import { defineStore } from 'pinia'
import { useApi } from './api'

export interface PropertyState {
    property: any
    measures: any[]
    funderReference: any
    recentlyViewed: RecentlyViewedProperty[]
    epc: any
}

export interface RecentlyViewedProperty {
    accessedDt: Date
    title: string
    description: string
    url: string
}

export const usePropertyStore = defineStore({
    id: 'property',
    state: () =>
        ({
            property: null,
            measures: [],
            funderReference: null,
            recentlyViewed: [],
            epc: null,
        }) as PropertyState,
    actions: {
        setProperty(property: any) {
            this.property = property
        },
        setMeasures(measures: any[]) {
            this.measures = measures
        },
        setFunderReference(funderReference: any) {
            this.funderReference = funderReference
        },
        setEpc(epc: any) {
            this.epc = epc
        },
        reset() {
            this.property = null
            this.measures = []
            this.funderReference = null
            this.epc = null
        },
        async getRecentlyViewed() {
            const api = useApi()
            const history = await api.getRecentlyViewedHistory()
            if (history.model) {
                this.recentlyViewed = history.model?.history
            }
        },
        async removeRecent() {
            const api = useApi()
            const response = await api.clearRecentlyViewedHistory()
            if (response.isSuccess) {
                this.recentlyViewed = []
            }
        },
    },
})
