import axios from 'axios'
import { defineStore } from 'pinia'
import { HTTP, FILE_HTTP } from '../services/http'
import { useAppStore } from './app'
import { FunderReferenceModel, FunderReferencePayloadModel } from './funderReferences'

export interface ApiState {
    silent: boolean
    count: number
    throw: number | boolean
    unwrap: boolean
    errors: string[]
}

export const useApi = defineStore({
    id: 'api',
    state: () =>
        ({
            silent: false, // if not silent, a loading overlay will pop up for api calls
            count: 0,
            throw: false, // if no throw, the default app notification will popup on non-200 status codes
            unwrap: true,
            errors: [],
        }) as ApiState,
    actions: {
        _handleError(e: any, errorMessage = '') {
            if (this.throw === true) throw e
            if (typeof this.throw === 'number' && this.throw === e.response?.status) throw e
            const data = e.response?.data
            if (data?.reasons) {
                data.reasons.forEach((reason: any) => {
                    this.error(`${reason.field} ${reason.reason}`)
                })
            } else {
                this.error(data?.message || e.message || errorMessage)
            }
            return false
        },
        async request(fn: any, errorMessage = '') {
            try {
                this.count += 1
                const result = await fn()
                const returnValue = this.unwrap ? result.data : result
                // Default true to catch empty string responses
                return returnValue || true
            } catch (e) {
                return this._handleError(e, errorMessage)
            } finally {
                this.count -= 1
                if (this.count === 0) {
                    this.$patch({
                        throw: false,
                        silent: false,
                    })
                }
            }
        },
        error(message: string) {
            if (message) {
                const app = useAppStore()
                app.addNotification({
                    type: 'error',
                    message,
                    timeout: 5000,
                })
            }
            return this
        },
        throwOnError(status: boolean | number | undefined = true) {
            this.throw = status
            return this
        },
        silently() {
            this.silent = true
            return this
        },
        getAppData() {
            return this.request(() => HTTP.get('/appData'), 'Failed to fetch app data.')
        },
        getAccount() {
            return this.request(() => HTTP.get(`/Account`))
        },
        getPresignedUrl(link: string) {
            return this.request(() => FILE_HTTP.post(`/PropertyCheckerFile/download`, link))
        },
        getPresignedPostUrl(filename: string) {
            return this.request(() => FILE_HTTP.post(`/PropertyCheckerFile/GetPresignedPost`, { filename }))
        },
        uploadFileToS3(url: string, file: any, config: any) {
            return this.request(() => axios.put(url, file, config))
        },
        lookupUPRN(uprn: string) {
            return this.request(() => HTTP.get(`/LookupUPRNs/uprn/${uprn}`))
        },
        lookupUPRNByReference(reference: string) {
            return this.request(() => HTTP.get(`/LookupUPRNs/reference/${reference}`))
        },
        searchFunderReferences(payload: any) {
            return this.request(() => HTTP.post(`/FunderReferenceSearch/search`, payload))
        },
        searchFunderReferencesAdvanced(payload: any) {
            return this.request(() => HTTP.post(`/FunderReferenceSearch/SearchAdvance`, payload))
        },
        createFunderReference(payload: FunderReferenceModel) {
            return this.request(() => HTTP.post(`/FunderReference`, payload))
        },
        updateFunderReference(payload: FunderReferencePayloadModel) {
            return this.request(() => HTTP.put(`/FunderReference`, payload))
        },
        activateFunderReference(funderReference: string) {
            return this.request(() => HTTP.put(`/FunderReference/${funderReference}/activate`))
        },
        deactivateFunderReference(funderReference: string) {
            return this.request(() => HTTP.put(`/FunderReference/${funderReference}/deactivate`))
        },
        getFunderReference(funderReference: string) {
            return this.request(() => HTTP.get(`/FunderReference/${funderReference}`))
        },
        removeFunderReference(funderReference: string) {
            return this.request(() => HTTP.delete(`/FunderReference/${funderReference}/remove`))
        },
        lookupAddress(postcode: string) {
            return this.request(() => HTTP.get(`/AddressLookup/${postcode}`))
        },
        getExports() {
            return this.request(() => HTTP.get(`/RetrofitExport/GetAll`))
        },
        getExportById(id: string) {
            return this.request(() => HTTP.get(`/RetrofitExport/${id}`))
        },
        deleteById(id: string) {
            return this.request(() => HTTP.delete(`/RetrofitExport/${id}`))
        },
        downloadById(id: string) {
            return this.request(() => HTTP.get(`/RetrofitExport/Download/csv/${id}`))
        },
        deletePendingById(id: string) {
            return this.request(() => HTTP.delete(`/RetrofitExport/Pending/${id}`))
        },
        createExportRequest(payload: any) {
            return this.request(() => HTTP.post(`/RetrofitExport`, payload))
        },
        getRecentlyViewedHistory() {
            return this.request(() => HTTP.get(`/RecentlyViewedHistory`))
        },
        clearRecentlyViewedHistory() {
            return this.request(() => HTTP.delete(`/RecentlyViewedHistory`))
        },
        getDashboardStats() {
            return this.request(() => HTTP.get(`/Dashboard`))
        },
        bulkUploadFunderReference(file: any) {
            const payload = new FormData()
            payload.append('file', file)
            return this.request(() =>
                HTTP.post(`/FunderReferenceBulkUpload`, payload, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }),
            )
        },
        getBulkUploadStatus(funderReferenceBulkUploadId: string) {
            return this.request(() => HTTP.get(`/FunderReferenceBulkUpload/${funderReferenceBulkUploadId}`))
        },
        getBulkUploadExceptions(funderReferenceBulkUploadId: string) {
            return this.request(() =>
                HTTP.get(`/FunderReferenceBulkUpload/${funderReferenceBulkUploadId}/file/exceptions`),
            )
        },
        getBulkUploadHistory() {
            return this.request(() => HTTP.get(`/FunderReferenceBulkUpload`))
        },
        getUsers() {
            return this.request(() => HTTP.get(`/FunderUser`))
        },
        getUserByEmail(accountId: string) {
            return this.request(() => HTTP.post(`/FunderUser/byemail`, { accountId }))
        },
        inviteUser(payload: any) {
            return this.request(() => HTTP.post(`/FunderUser/invite`, payload))
        },
        disableUser(accountId: string) {
            return this.request(() => HTTP.put(`/FunderUser/disable`, { accountId }))
        },
        enableUser(accountId: string) {
            return this.request(() => HTTP.put(`/FunderUser/enable`, { accountId }))
        },
        resendUserInvite(accountId: string) {
            return this.request(() => HTTP.post(`/FunderUser/sendinvite`, { accountId }))
        },
        reset2FA(accountId: string) {
            return this.request(() => HTTP.put(`/FunderUser/reset`, { accountId }))
        },
        updateUserRoles(accountId: string, roles: string[]) {
            return this.request(() => HTTP.put(`/FunderUser/roles`, { accountId, roles }))
        },
        acceptTerms() {
            return this.request(() => HTTP.post(`/Account/acceptTerms`))
        },
        requestTotp() {
            return this.request(() => HTTP.post(`/Account/requestTotp`))
        },
        onCompleteTotp(form: any) {
            return this.request(() => HTTP.post(`/Account/completeTotp`, form))
        },
        requestSms(form: any) {
            return this.request(() => HTTP.post(`/Account/requestSms`, form))
        },
        onCompleteSms(form: any) {
            return this.request(() => HTTP.post(`/Account/completeSms`, form))
        },
    },
})
